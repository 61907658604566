<template>
  <div class="hello">
    <!-- 模块一 -->
    <div class="contentone">
      <el-container style="width: 100%; height: 100%;">
        <el-header style="width: 100%; display: flex; justify-content: space-between; align-items: center; height: 60px;">
          <div class="logbox">
            <img
              src="../img/logo24.png"
              class="log"
            />
          </div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item style="color: #fff; padding: 0 40px; font-size: 18px; font-weight: 900;" index="1" >首页</el-menu-item>
            <el-submenu index="2">
              <template slot="title">业务合作</template>
              <el-menu-item style="text-align: center;"  index="2-1" @click="goCarFactory">车场入驻</el-menu-item>
              <el-menu-item style="text-align: center;" index="2-1" @click="goCommerce">商务合作</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">了解停小帮</template>
              <el-menu-item style="text-align: center;" index="3-1" @click="goFistView"
                >关于我们</el-menu-item
              >
              <el-menu-item style="text-align: center;" index="3-2" @click="goProblem"
                >常见问题</el-menu-item
              >
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">预约车位</template>
              <el-menu-item  index="4-1" style="height: 130px;">
                <div style="text-align: center;">
                  <img src="../img/erweima.png" style="width: 100px;height: 100px;"/>
                  <p style="font-size: 16px;color: black;">微信扫码预约车位</p>
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-header>
        
        <el-main style="width: 100%; height: 100%;">
          <!--  -->
          <div class="contone">
            <div class="onebox">
              <div class="iponebox">
                <img
                  src="../img/appsy3.png"
                  class="ipone"
                />
              </div>
              <div class="textonebox">
                <div class="title">
                  <div class="h1">停小帮</div>
                  <div class="h2" style="margin-top: 20px;">停车省钱小帮手</div>
                </div>
                <div class="code">
                  <img src="../img/erweima.png" class="apppho" />
                  <!-- <img
                    src="../img/logo2.png"
                    class="appcode"
                  /> -->
                  <!-- <span>停小帮</span> -->
                  <div style="display: flex; flex-direction: column; justify-content: space-around; align-items: center;">
                    <div class="pbox">微信扫码预约车位</div>
                    <div class="pbox">享超低价 平台保障 安全便捷</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
        </el-main>
      </el-container>
    </div>
    <!--  -->
    <!-- 模块二 -->
    <div class="conttwo">
      <div class="twotop">
        <div class="spanbox">了解服务</div>
        <p>方便快捷的停车流程</p>
      </div>
      <!--  -->
      <div class="twofoot">
        <div class="textbox">
          <span><b>自泊</b>流程</span>
        </div>
        <div class="stepbox">
          <span class="ictxtex"></span>
          <div class="iconbox">
            <img src="../img/在线预约.png" class="sticon" />
          </div>
          <span class="ictxtex"></span>
          <div class="iconbox">
            <img src="../img/停车场.png" class="sticon" />
          </div>
          <span class="ictxtex"></span>
          <div class="iconbox">
            <img src="../img/接送机.png" class="sticon" />
          </div>
          <span class="ictxtex"></span>
          <div class="iconbox">
            <img src="../img/摆渡车.png" class="sticon" />
          </div>
          <span class="ictxtex"></span>
        </div>
        <div class="briefbox">
          <p>1.在线预约下单</p>
          <p>2.驾车去车场</p>
          <p>3.摆渡车送至机场</p>
          <p>4.返程坐接驳车取车</p>
        </div>
        <span class="brief"
          >说明：部分车场距离目的地较近，步行直达，暂不提供摆渡车服务</span
        >
      </div>
    </div>
    <!--  -->
    <!-- 模块三 -->
    <div class="conthree">
      <p style="width: 100%;text-align: center;">服务开通城市</p>

      <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
        <span class="city" v-for="(item, index) in city" :key="index">{{
          item
        }}</span>
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
        <i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i>
        <i></i><i></i><i></i><i></i><i></i><i></i>
      </div>

      <pre>更多城市陆续开通中，期待您的加入!</pre>
    </div>
    <!-- footer -->
    <div class="footer">
      <p>Copyright @2024 杭州莱圣科技有限公司 <a style="text-decoration: none; color: black;" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">浙ICP备2024066447号-1</a></p>
    </div>
    <h1 style="visibility: hidden;">机场停车，机场停车费，机场停车场收费多少钱一天，机场停车场收费标准，高铁站停车，高铁站停车场收费标准，高铁站停车费，高铁站停车场收费多少钱一天，小强停车，淘车位，泊安飞，UU停车，笨鸟停车</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      city: [
        "上海",
        "杭州",
        "北京",
        "广州",
        "成都",
        "南京",
        "深圳",
        "济南",
        "郑州",
        "青岛",
        "无锡",
        "天津",
        "长沙",
        "重庆",
        "合肥",
        "珠海",
        "咸海",
        "南昌",
        "沈阳",
        "武汉",
        "绍兴",
        "昆明",
        "宁波",
        "石家庄",
        "温州",
        "苏州",
        "扬州",
        "福州",
        "哈尔滨",
        "贵阳",
        "南宁",
        "西安",
        "长春",
        "常州",
        "太原",
        "烟台",
        "南通",
        "银川",
        "厦门",
        "惠州",
        "桂林",
        "郴州",
        "海口",
        "兰州",
        "宜昌",
        "乌鲁木齐",
        "泉州",
        "金华",
        "三亚",
        "襄阳",
        "锦阳",
        "吉安",
        "营口",
        "黄山",
        "临沂",
        "衡阳",
        "遵义",
        "大连",
        "嘉兴",
        "拉萨",
        "台州",
        "西宁",
      ],
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    aboutfun() {
      console.log(4444);
      this.$router.push("/about");
    },
    goCarFactory() {
      this.$router.push("/carFactory");
    },
    goCommerce() {
      this.$router.push("/commerceView");
    },
    goFistView() {
      this.$router.push("/about")
    },
    goProblem() {
      this.$router.push("/commonView")
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  padding: 0;
  margin: 0;
}
/* 模块一 */
.contentone {
  width: 100%;
  /* height: 100vh; */
  background-image: url("../img/bjtp03.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: rgba(186, 185, 185, 1);
}
@media screen and (max-width: 900px) {
  .contentone {
    width: 100%;
    /* height: 600px; */
  }
}
.el-menu {
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-demo {
  padding-right: 6vw;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}

.logbox {
  width: 80px;
  height: 60px;
  /* width: 30%; */
  /* height: 60px; */
  line-height: 60px;
  /* float: left; */
  display: flex;
  align-items: center;
  padding-left: 50px;
  img {
    width: 80px;
    height: 60px;
    float: left;
  }
}
.log {
  width: 80px;
  height: 60px;
  float: left;
}
@media screen and (max-width: 900px) {
  .logbox {
    padding-left: 0;
    width: 80px;
    height: 40px;
    /* width: 30%; */
    /* height: 60px; */
    line-height: 60px;
    /* float: left; */
    display: flex;
    align-items: center;
    /* padding-left: 50px; */
    img {
      width: 80px;
      height: 60px;
      float: left;
    }
  }
}
/*  */
.contone {
  width: 55%;
  /* height: 90%; */
  color: white;
  margin: auto;
  margin-top: 30px;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .contone {
    width: 60%;
    /* height: 95%; */
  }
}
@media screen and (max-width: 900px) {
  .contone {
    width: 90%;
    /* height: 95%; */
  }
}
.onebox {
  width: 100%;
  /* margin: auto; */
  /* height: 100%; */
  position: relative;
}
.textonebox {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* align-items: center; */
  top: 50%;
  top: 12%;
  right: 0;
  /* width: 40%; */
  /* height: 100%; */
}
.title {
  /* position: absolute;
  top: 10%;
  right: -8%; */
  color: rgb(99, 99, 99);
  .h1,
  .h2 {
    text-align: left;
    /* width: 200px; */
    /* height: 60px; */
    font-size: 40px;
    font-weight: 900;
  }
  .h2 {
    width: 350px;
    font-size: 34px;
  }
}
@media screen and (max-width: 900px) {
  .title {
    /* position: absolute;
    top: 10%;
    right: 0; */
    .h1,
    .h2 {
      text-align: left;
      width: 150px;
      /* height: 60px; */
      font-weight: 900;
      font-size: 20px;
    }
    .h2 {
      width: 150px;
      font-size: 20px;
    }
  }
}
.iponebox {
  width: 38%;
  /* height: 100%; */
  /* background-color: #333;   */
  border-radius: 30px;
}
@media screen and (max-width: 900px) {
  .iponebox {
    width: 40%;
    /* height: 80%; */
  }
}
.ipone {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-left: 20%;
  /* margin: auto; */
  /* margin-top: 10px; */
}
/*  */
.code {
  display: flex;
  padding: 10px;
  /* width: 280px; */
  /* height: 110px; */
  background-color: #222;
  /* position: absolute;
  top: 40%;
  right: 15%; */
  margin-top: 40px;
  border-radius: 15px;
  overflow: hidden;
  span {
    float: left;
    margin-top: -50px;
    margin-left: 10px;
    height: 20px;
  }
  .pbox {
    /* width: 160px; */
    font-size: 12px;
    color: #fff;
    /* float: right; */
    /* line-height: 15px; */
    /* margin-top: 15px; */
    padding-left: 6px;
    text-wrap: nowrap;
  }
}
@media screen and (max-width: 900px) {
  .code {
    display: flex;
    padding: 10px;
    width: 35vw;
    /* height: 19vw; */
    background-color: #222;
    /* position: absolute;
    top: 40%;
    right: 15%; */
    margin-top: 40px;
    border-radius: 15px;
    span {
      float: left;
      margin-top: -70px;
      margin-left: 10px;
      height: 20px;
    }
    .pbox {
      /* width: 50px; */
      /* height: 30px; */
      /* overflow: hidden; */
      font-size: 7px;
      color: gray;
      /* float: right; */
      /* line-height: 15px; */
      /* margin-top: 15px; */
      margin-right: 10px;
      text-wrap: nowrap;
    }
  }
}
.appcode {
  width: 60px;
  height: 30px;
  /* float: left; */
  /* margin-top: 15px; */
  margin-left: 20px;
  border-radius: 5px;
}
@media screen and (max-width: 900px) {
  .appcode {
    width: 40px;
    height: 20px;
    /* float: left; */
    /* margin-top: 10px; */
    margin-left: 10px;
    border-radius: 5px;
  }
}
.apppho {
  width: 80px;
  height: 80px;
  /* margin-top: 15px; */
  /* float: left; */
  /* margin-left: 15px; */
  border-radius: 5px;
}
@media screen and (max-width: 900px) {
  .apppho {
    width: 16vw;
    height: 16vw;
    /* margin-top: 8px; */
    /* float: left; */
    /* margin-left: 5px; */
    border-radius: 5px;
  }
}
/* 模块二 */
.conttwo {
  height: 400px;
}
.twotop {
  height: 150px;
  background-color: #f8f8f8;
  border-bottom: 2px solid #e9e9e9;
  .spanbox {
    font-size: 40px;
    display: inline-block;
    margin-top: 40px;
  }
  p {
    color: gray;
  }
}
.textbox {
  height: 80px;
  line-height: 80px;
}
.stepbox {
  width: 80%;
  display: flex;
  margin: auto;
  text-align: center;
}
.ictxtex {
  display: inline-block;
  width: 15%;
  height: 10px;
  background-color: #f0f3fa;
  border-radius: 5px;
  margin-top: 20px;
}
.iconbox {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f3fa;
  margin: 0 5px;
}
.sticon {
  margin: 10px auto;
}
.briefbox {
  width: 80%;
  height: 50px;
  display: flex;
  margin: auto;
  justify-content: center;
  line-height: 50px;
  p {
    width: 20%;
    text-align: center;
  }
}
@media screen and (max-width: 900px) {
  .briefbox {
    width: 80%;
    height: 50px;
    display: flex;
    margin: auto;
    justify-content: center;
    line-height: 50px;
    p {
      font-size: 12px;
      width: 20%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.brief {
  color: gray;
  /* float: left; */
  text-align: center;
  /* margin-left: 10%; */
}
/* 模块三 */
.conthree {
  overflow: auto;
  width: 80%;
  margin: auto;
  text-align: center;
  p {
    font-size: 30px;
    font-weight: 900;
    height: 70px;
    text-align: left;
  }
  pre {
    font-size: 24px;
    font-weight: 900;
    text-align: center;
    margin-top: 60px;
    color: #1058b8;
  }
  @media screen and (max-width: 600px) {
    pre {
      font-size: 16px;
      text-align: center;
    }
  }
}
.city {
  /* display: inline-block; */
  /* margin: 10px 26px; */
  padding: 20px 20px 20px 0;
  font-size: 20px;
}
.city i {
  width: 20px;
}
/*  */
.el-header {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #ffffff;
}
.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
}

/* .el-main {
  color: #333;
  text-align: center;
  line-height: 160px;
} */

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
/* footer */
.footer {
  width: 100%;
  background-color: #f8f9fc;
  height: 200px;
  margin-top: 100px;
  line-height: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.appbtn {
  /* position: relative; */
}
/deep/.el-menu--popup {
  min-width: 100px!important;
  width: 100px!important;
}
/deep/.el-submenu__title {
  color: #fff!important;
  font-size: 18px; font-weight: 700;
}
/deep/.el-submenu__title:hover {
  background-color: rgba(0,0,0,0)!important;
  font-size: 18px; font-weight: 700;
}
/* /deep/.el-menu--popup {
  min-width: 100px!important;
} */
.el-menu--popup {
  z-index: 9999;
  min-width: 100px!important;
  width: 100px!important;
}
/deep/.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0);
}
</style>
