import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import CarFactoryView from '../views/CarFactoryView.vue'
import CommerceView from '../views/CommerceView.vue'
import CommonView from '../views/CommonView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // component: () => import('../views/AboutView.vue')

    component: AboutView
  },
  {
    path: '/carFactory',
    name: 'carFactory',
    // component: () => import('../views/CarFactoryView.vue')

    component: CarFactoryView
  },
  {
    path: '/commerceView',
    name: 'commerceView',
    // component: () => import('../views/CommerceView.vue')

    component: CommerceView
  },
  {
    path: '/commonView',
    name: 'commonView',
    // component: () => import('../views/CommonView.vue')

    component: CommonView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
