<template>
  <div class="about">
    <!-- 模块一 -->
    <div class="contentone">
      <el-container>
        <el-header style="width: 100%; display: flex; justify-content: space-between; align-items: center; height: 60px;">
          <div class="logbox">
            <img
              src="../img/logo24.png"
              class="log"
            />
          </div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" style="color: #fff;font-size: 18px; padding: 0 40px; font-weight: 900;" @click="goIndex">首页</el-menu-item>
            <el-submenu index="2">
              <template slot="title">业务合作</template>
              <el-menu-item style="text-align: center;" index="2-1" @click="goCarFactory">车场入驻</el-menu-item>
              <el-menu-item style="text-align: center;" index="2-1" @click="goCarShop">商务合作</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">了解停小帮</template>
              <el-menu-item index="3-1" style="text-align: center;"
                >关于我们</el-menu-item
              >
              <el-menu-item index="3-2" @click="goProblem" style="text-align: center;"
                >常见问题</el-menu-item
              >
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">预约车位</template>
              <el-menu-item  index="4-1" style="height: 130px;">
                <div style="text-align: center;">
                  <img src="../img/erweima.png" style="width: 100px;height: 100px;"/>
                  <p style="font-size: 16px;color: black;margin-top: -5px;">微信扫码预约车位</p>
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-header>
        <el-main>
          <div class="cont">
            <!-- <h1>about</h1>
            <h1>关于停小帮</h1> -->
          </div>
        </el-main>
      </el-container>
    </div>
    <!-- 模块二 -->
    <div class="contwo">
      <h3 style="text-align: center;font-size: 20px;font-weight: 900;">关于停小帮</h3>
      <p>
        停小帮是杭州莱圣科技有限公司旗下的预约停车平台，专注于为自驾前往机场、高铁站等
        交通枢纽的车主们提供便捷的停车服务。目前，服务网络已遍及全国60多个城市，成为预
        约停车领域的新兴品牌。
      </p>
      <!--  -->
      <p>
        停小帮平台以专业的技术手段和前瞻性的市场洞察，致力于通过移动互联网技术解决广大
        车主在出行过程中面临的停车难、停车贵的问题。采用精准的车位信息匹配系统，确保车主
        能够迅速找到性价比最高的停车位;通过实时的空闲车位监控系统，为车主提供即时的车位信
        息更新;同时还提供一键式预约与导航服务，让车主的停车体验更加便捷和高效。
      </p>
      <!--  -->
      <p>
        停小帮不仅为车主提供了最方便、最经济的停车选择，还通过优化停车费用结构，为车主带来
        了实质性的经济效益。在停小帮预约车位，车主每天仅需支付最低10元的停车费，相较于直接
        停放在机场或高铁站内的高易费用，选择停小帮最高可节省80%的停车费用，极大地减轻了车主
        的经济负担。
      </p>
      <!--  -->
      <p>
        作为预约停车领域的专业品牌，停小帮将持续致力于提升服务质量，为车主创造更加优质的停车体验。
      </p>
    </div>
    <!-- 模块三 -->
    <div class="conthree">
      <!--  -->
      <div class="three">
        <img src="../img/使命.png" class="icon" />
        <h4>使命</h4>
        <p>停车省钱，出行无忧</p>
      </div>
      <!--  -->
      <div class="three">
        <img src="../img/定位.png" class="icon" />
        <h4>定位</h4>
        <p>预约停车专家</p>
      </div>
      <!--  -->
      <div class="three">
        <img src="../img/快速_加速.png" class="icon" />
        <h4>Slogen</h4>
        <p>停车省钱小帮手</p>
      </div>
      <!--  -->
    </div>
    <!-- 模块四 -->
    <div class="contfour">
      <!-- <h1>联系我们</h1> -->
      <!-- <div class="map-container" ref="mapContainer">

      </div> -->
      <div class="contact">
        <p>联系地址:浙江省杭州市上城区秋涛路258号3号楼10层</p>
        <p>如您有任何问题或建议，可发送邮件至tingxiaobang@163.com</p>
      </div>
      <!--  -->
      <!-- <div class="common">
        <h1>常见问题</h1>
        <h3>1、停车流程是什么样的</h3>
        <h5>①、在线预约下单</h5>
        <p>
          通过停小帮APP、停小帮微信小程序、停小帮微信公众号选择您要去的站点和心仪的停车场进行下单
        </p>
        <h5>②、开车去停车场</h5>
        <p>下单成功后，点击订单详情页中的导航按钮开车前往停车场</p>
        <h5>③、摆渡车送至机场、高铁站</h5>
        <p>停好车后，停车场会安排司机送您去机场/高铁站</p>
        <h6>备注:部分停车场离目的地非常近，步行可直达，无需接送</h6>
        <h5>④、返程摆渡车接回取车</h5>
        <p>
          快下飞机/高铁时通过订单详情页中的联系停车场拨打停车场电话，约好接机时间和地点，停车场安排司机接您回停车场。
        </p>
        <h3>2、停车场怎么计费</h3>
        <p>
          停车费是以实际停、取车时间来进行计费的，有24小时和自然日两种计费模式，如果按自然日计费，一个日期算一天，例如5月1日至5月5日为5天，如果按24小时计费，不满24小时按24小时计算，例如5月1日12点至5月5日12点为4天。
        </p>
        <h3>3、停车场的位置在哪里</h3>
        <p>
          停车场在机场、高铁站内部或周边，下单成功后根据短信中的导航地址或订单详情页里的一键导航前往停车场。
        </p>
        <h3>4、预约成功了会不会出现没有车位的情况</h3>
        <p>预约成功后，就可以确保车位的，请不用担心。</p>
        <h3>5、需要提前多久预约车位</h3>
        <p>
          建议购买好机票或高铁票后就可以订车位了，节假日车位紧张，请尽早预订。
        </p>
        <h3>6、需要提前多久到达停车场</h3>
        <p>
          国内航班建议提前2小时到达停车场，国际航班建议提前3小时到达停车场，高铁建议提前1.5小时到达停车场。
        </p>
      </div> -->
      <!-- 预约车位 -->
      <!-- <div class="pre-book"> -->
        <!-- <h1>预约车位</h1> -->
        <!-- <button @click="photofun()" class="pre-bookbtn">立即预约</button> -->
        <!-- <el-button
          type="text"
          @click="dialogTableVisible = true"
          class="pre-bookbtn"
          >立即预约</el-button
        >
        <el-dialog title="立即预约" :visible.sync="dialogTableVisible">
          <img src="../img/erweima.png" class="appcode" />
          <p>微信扫码预约车位</p>
        </el-dialog>
      </div> -->
    </div>
    <!-- footer -->
    <div class="footer">
      <!-- <p>Copyright @2024 杭州莱圣科技有限公司浙ICP备2024066447号-1</p> -->
      <p>Copyright @2024 杭州莱圣科技有限公司 <a style="text-decoration: none; color: black;" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/recordQuery">浙ICP备2024066447号-1</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapWithMarker",
  data() {
    return {
      activeIndex: "3-1",
      activeIndex2: "1",
      map: null, // 地图实例
      marker: null, // 点标点实例
      dialogTableVisible: false,
    };
  },
  mounted() {
    // this.initMap();
  },
  methods: {
    //预约车位
    initMap() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://webapi.amap.com/maps?v=1.4.15&key=${"	696d0c643120c5c2de1a6bba8364274e"}&callback=initAMap`;
      document.body.appendChild(script);

      window.initAMap = () => {
        this.AMap = window.AMap;
        this.createMap();
      };
    },
    createMap() {
      this.map = new this.AMap.Map(this.$refs.mapContainer, {
        zoom: 10, // 缩放级别
        center: [120.188447, 30.234097], // 中心点坐标
        // 120.188447,30.234097
      });

      // 添加点标点
      this.addMarker();
    },
    addMarker() {
      const marker = new this.AMap.Marker({
        position: [120.188447, 30.234097],
        map: this.map,
      });
      this.marker = marker;
      marker.on("click", () => {
        alert("你点击了点标点！");
      });
    },
    photofun() {
      console.log(444);
    },
    goCarFactory() {
      this.$router.push("/carFactory");
    },
    goIndex() {
      this.$router.push("/");
    },
    goProblem() {
      this.$router.push("/commonView")
    }
  },
};
</script>

<style scoped>
.about {
  /* position: relative; */
}
/* 组件样式*/
.el-menu {
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-demo {
  padding-right: 6vw;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}
.el-header {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #333;
  text-align: center;
  line-height: 60px;
  padding: 0;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #ffffff;
}
.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
/*  */
/* 模块一 */
.contentone {
  height: 100%;
  padding-bottom: 20px;
  background-image: url("../img/bjtp04.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(186, 185, 185, 1);
  /* backdrop-filter: blur(10px);  */
  /* filter: blur(3px); */
}
.logbox {
  /* width: 30%; */
  /* height: 60px; */
  padding: 0 6px;
  line-height: 60px;
  float: left;
  align-items: center;
  padding-left: 50px;
  img {
    width: 80px;
    height: 60px;
    float: left;
  }
}
@media screen and (max-width: 900px) {
  .logbox {
    padding-left: 0;
  }
}
.cont {
  height: 340px;
  color: white;
  line-height: 250px;
  h1 {
    height: 70px;
    font-size: 36px;
    margin: 0;
    padding: 0;
  }
}
/* 模块二 */
.contwo {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  margin: auto;
  text-align: left;
  color: #fff;
  font-size: 18px;
}
@media screen and (max-width: 900px) {
  .contwo {
    top: 60px;
    font-size: 12px;
    /* height: 400px; */
    width: 90%;
    overflow: scroll;
  }
}
/* 模块三 */
.conthree {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 100px 10%;
}
.three {
  width: 30%;
  border-right: 1px solid rgb(237, 235, 235);
  justify-content: center;
}
.three:nth-child(3) {
  border: none;
}
/* 联系我们 */
.map-container {
  width: 100%;
  height: 400px;
  margin-top: 40px;
  background-image: url();
}
/* 常见问题 */
.common {
  width: 80%;
  margin: 50px auto;
  h3,
  h5,
  h6,
  p {
    text-align: left;
  }
  h6 {
    color: #4a86ff;
  }
  h3 {
    margin: 30px 0;
  }
}
/* 预约车位 */
.pre-bookbtn {
  width: 100px;
  height: 40px;
  border: none;
  background-color: #4a86ff;
  border-radius: 5px;
  color: white;
}
.appcode {
  width: 200px;
  height: 200px;
}
/* footer */
.footer {
  width: 100%;
  background-color: #f8f9fc;
  height: 200px;
  margin-top: 100px;
  line-height: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.log {
  width: 80px;
  height: 60px;
  float: left;
}
/deep/.el-submenu__title {
  color: #fff!important;
  font-size: 18px; font-weight: 700;
}
/deep/.el-submenu__title:hover {
  background-color: rgba(0,0,0,0)!important;
  font-size: 18px; font-weight: 700;
}
/deep/.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0);
}
</style>
