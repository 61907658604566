<template>
  <div class="about">
    <!-- 模块一 -->
    <div class="contentone">
      <el-container style="width: 100%; height: 100%;">
        <el-header style="width: 100%;background-color: rgba(255, 255, 255, 0.8); display: flex; justify-content: space-between; align-items: center; height: 60px;">
          <div class="logbox">
            <img
              src="../img/logo24.png"
              class="log"
            />
          </div>
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" style="font-size: 18px; padding: 0 40px; font-weight: 900;" @click="goFistView">首页</el-menu-item>
            <el-submenu index="2">
              <!-- <el-menu-item index="2-1" @click="goCarFactory">车场入驻</el-menu-item> -->
              <template slot="title">业务合作</template>
              <el-menu-item index="2-1" style="text-align: center;" @click="goCarFactory">车场入驻</el-menu-item>
              <el-menu-item index="2-1" style="text-align: center;" >商务合作</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template slot="title">了解停小帮</template>
              <el-menu-item index="3-1" @click="aboutfun" style="text-align: center;"
                >关于我们</el-menu-item
              >
              <el-menu-item index="3-2" @click="goProblem" style="text-align: center;"
                >常见问题</el-menu-item
              >
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">预约车位</template>
              <el-menu-item  index="4-1" style="height: 130px;">
                <div style="text-align: center;">
                  <img src="../img/erweima.png" style="width: 100px;height: 100px;"/>
                  <p style="font-size: 16px;color: black; margin-top: -5px;">微信扫码预约车位</p>
                </div>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </el-header>
        <!-- <el-main
          ><div class="cont">
            <h1>about</h1>
            <h1>关于停小帮</h1>
          </div>
        </el-main> -->
      </el-container>
    </div>
    
    <div class="formbox">
      <div style="font-size: 20px;font-weight: 900; margin: 10px 0 50px 0">
        请填写以下合作信息，收到信息后停小帮将尽快联系您
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="公司名称" prop="name1">
          <el-input v-model="ruleForm.name1" placeholder="请输入公司名称"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="iphone">
          <el-input v-model="ruleForm.iphone"  placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="合作内容" prop="position">
          <el-input v-model="ruleForm.position" placeholder="请输入合作内容"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script>
export default {
  name: "CarFactoryView",
  data() {
    return {
      activeIndex: "2-1",
      activeIndex2: "1",
      map: null, // 地图实例
      marker: null, // 点标点实例
      dialogTableVisible: false,
      ruleForm: {
        name1: '',
        name: '',
        iphone: '',
        position: '',
        station: '',
        space: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        name1: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        iphone: [
          { required: true, message: '请输入手机号码', trigger: 'change' }
        ],
        position: [
          { required: true, message: '请输入合作内容', trigger: 'change' }
        ],
        position1: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        station: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        space: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
      }
    };
  },
  mounted() {
    // this.initMap();
  },
  methods: {
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
    //预约车位
    // initMap() {
    //   const script = document.createElement("script");
    //   script.type = "text/javascript";
    //   script.async = true;
    //   script.src = `https://webapi.amap.com/maps?v=1.4.15&key=${"	696d0c643120c5c2de1a6bba8364274e"}&callback=initAMap`;
    //   document.body.appendChild(script);

    //   window.initAMap = () => {
    //     this.AMap = window.AMap;
    //     this.createMap();
    //   };
    // },
    // createMap() {
    //   this.map = new this.AMap.Map(this.$refs.mapContainer, {
    //     zoom: 10, // 缩放级别
    //     center: [120.188447, 30.234097], // 中心点坐标
    //     // 120.188447,30.234097
    //   });

    //   // 添加点标点
    //   this.addMarker();
    // },
    // addMarker() {
    //   const marker = new this.AMap.Marker({
    //     position: [120.188447, 30.234097],
    //     map: this.map,
    //   });
    //   this.marker = marker;
    //   marker.on("click", () => {
    //     alert("你点击了点标点！");
    //   });
    // },
    // photofun() {
    //   console.log(444);
    // },
    goCarFactory() {
      this.$router.push("/carFactory");
    },
    // goCommerce() {
    //   this.$router.push("/commerceView");
    // },
    goFistView() {
      this.$router.push("/")
    },
    aboutfun() {
      this.$router.push("/about")
    },
    goProblem() {
      this.$router.push("/commonView")
    }
  },
};
</script>

<style scoped>
/* 组件样式*/
.el-menu {
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-demo {
  padding-right: 6vw;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
}
.el-header {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 0px #ffffff;
}
.el-aside {
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
/*  */
/* 模块一 */
.contentone {
  /* height: 100px; */
  background-image: url("https://nimg.ws.126.net/?url=http%3A%2F%2Fdingyue.ws.126.net%2F2023%2F0922%2Faaab06f4j00s1cf52001md200nr00dqg00gr009o.jpg&thumbnail=660x2147483647&quality=80&type=jpg");
  background-repeat: repeat;
  /* background-size: 100% 100%; */
  /* background-color: skyblue; */
}
.logbox {
  /* width: 30%; */
  /* height: 60px; */
  line-height: 60px;
  /* float: left; */
  display: flex;
  align-items: center;
  padding-left: 50px;
  img {
    width: 80px;
    height: 60px;
    float: left;
  }
}
@media screen and (max-width: 900px) {
  .logbox {
    padding-left: 0;
  }
}
.cont {
  height: 340px;
  color: white;
  line-height: 250px;
  h1 {
    height: 70px;
    font-size: 36px;
    margin: 0;
    padding: 0;
  }
}
/* 模块二 */
.contwo {
  width: 80%;
  margin: auto;
  text-align: left;
}
/* 模块三 */
.conthree {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
  margin: 100px auto;
}
.three {
  width: 30%;
  border-right: 1px solid rgb(237, 235, 235);
  justify-content: center;
}
.three:nth-child(3) {
  border: none;
}
/* 联系我们 */
.map-container {
  width: 100%;
  height: 400px;
  margin-top: 40px;
}
/* 常见问题 */
.common {
  width: 80%;
  margin: 50px auto;
  h3,
  h5,
  h6,
  p {
    text-align: left;
  }
  h6 {
    color: #4a86ff;
  }
  h3 {
    margin: 30px 0;
  }
}
/* 预约车位 */
.pre-bookbtn {
  width: 100px;
  height: 40px;
  border: none;
  background-color: #4a86ff;
  border-radius: 5px;
  color: white;
}
.appcode {
  width: 200px;
  height: 200px;
}
/* footer */
.footer {
  width: 100%;
  background-color: #f8f9fc;
  height: 200px;
  margin-top: 100px;
  line-height: 200px;
}
.formbox {
  padding-top: 100px;
  width: 50vw; 
  text-align: center; 
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .formbox {
    padding-top: 100px;
    width: 80vw; 
    text-align: center; 
    margin: 0 auto;
  }
}
.log {
  width: 80px;
  height: 60px;
  float: left;
}
/deep/.el-submenu__title {
  font-size: 18px; font-weight: 700;
}
/deep/.el-submenu__title:hover {
  background-color: rgba(0,0,0,0)!important;
  font-size: 18px; font-weight: 700;
}
/deep/.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0);
}
</style>
